<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import draggable from 'vuedraggable'

	export default {
		name: "BoHome",
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "BoHome",
				font_style: {},
				font_size: {},
				sub_font_size: {},
				font_motion: {},
				categories: {},
				instagram_client_id: {},
				row1: {},
				row2: {
					mpc_show: true,
					mpc_is_active: 'Y'
				},
				input: {},
				desc: '',
				row: {
					as_title_font_color: '#000000',
					as_is_active: "Y",
					as_title_font_type: 'HolidayFree',
					as_title_font_size: '60px',
					as_title_motion_style: 'fade',
					as_img_motion: 'fade'
				}
			}
		},
		computed: {
			urlCodeInstagram() {
				var $client_id = this.instagram_client_id.aws_val
				return "https://www.instagram.com/oauth/authorize?client_id=" + $client_id +
					"&redirect_uri=https://dimatique.com/backoffice/home&scope=user_profile,user_media&response_type=code"
			},
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
			this.generateTokenInstagram()
			this.page.addItem = false
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
			'input.about'(v) {
				BOGen.apirest('/' + this.Name, {
					data: v,
					type: 'statusAbout'
				}, (err, resp) => {}, "POST");
			},
			'input.fav'(v) {
				BOGen.apirest('/' + this.Name, {
					data: v,
					type: 'statusFavorite'
				}, (err, resp) => {}, "POST");
			},
			'input.event'(v) {
				BOGen.apirest('/' + this.Name, {
					data: v,
					type: 'statusEvent'
				}, (err, resp) => {}, "POST");
			},
			'input.blog'(v) {
				BOGen.apirest('/' + this.Name, {
					data: v,
					type: 'statusBlog'
				}, (err, resp) => {}, "POST");
			},
			'input.feed'(v) {
				BOGen.apirest('/' + this.Name, {
					data: v,
					type: 'statusFeed'
				}, (err, resp) => {}, "POST");
			},
		},
		methods: {
			endDrag() {
				BOGen.apirest('/' + this.Name, {
					data: this.data.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
			endDragCat() {
				BOGen.apirest('/' + this.Name, {
					data: this.categories,
					type: 'sortCat'
				}, (err, resp) => {
					this.refreshData()
				}, "POST");
			},
			addSlide() {
				this.row = {
					as_title_font_color: '#000000',
					as_is_active: "Y",
					as_title_font_type: 'HolidayFree',
					as_title_font_size: '60px',
					as_title_motion_style: 'fade',
					as_img_motion: 'fade'
				}
				this.row.type = 'add'
			},
			editSlide(v) {
				$('#collapseExample').addClass('show')
				this.row = v;
				this.row.type = 'update'
			},
			editFoto(v) {
				console.log(v)
				$('#about_modal').modal();
			},
			submitUpdate() {
				this.row1.type = 'updateAbout'
				BOGen.apirest('/' + this.Name, this.row1, (err, resp) => {
					if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.about-info')
					if (resp.success) {
						Gen.info(resp.message, 'success', 2000, '.about-info')
						this.refreshData()
						setTimeout(() => {
							$('#about_modal').modal('hide')
						}, 2100)
					}
				}, "POST");
			},
			submitCollection() {
				BOGen.apirest('/' + this.Name, this.row2, (err, resp) => {
					if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.col-info')
					if (resp.success) {
						Gen.info(resp.message, 'success', 2000, '.about-info')
						this.refreshData()
						setTimeout(() => {
							$('#collapseExample2').removeClass('show')
						}, 2100)
					}
				}, "POST");
			},
			addCollection() {
				this.row2 = {
					mpc_show: true,
					mpc_is_active: 'Y'
				}
				this.row2.type = 'addCollection'
			},
			editCollection(v) {
				$('#collapseExample2').addClass('show')
				this.row2 = v
				this.row2.type = 'editCollection'
			},
			getInstagram() {
				this.row2.type = 'generateInstagram'
				this.loadingOverlay = true
				BOGen.apirest('/' + this.Name, this.row2, (err, resp) => {
					this.loadingOverlay = false
					if (err) return swal(err.resp.message, "", "warning")
					if (resp.error) return swal("Error!", 'Generate Instagram Error.', "danger");
					if (resp.success) {
						swal("Success!", resp.message, "success");
					}
				}, "POST");
			},
			generateTokenInstagram() {
				if (!this.$route.query.code) return console.log("no code")
				console.log("generate running...")
				var input = {}
				input.code = this.$route.query.code
				input.type = "generateTokenInstagram"
				input.url = this.baseUrl
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (err) return swal(err.resp.message, '', 'warning')
					swal(resp.message, '', 'success')
				}, "POST")
			},

		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		<div class="row">
			<!-- Start Slider -->
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Slide Show</h5>
						<draggable v-model="data.data" class="row" @end="endDrag">
							<div style="cursor:move;" class="col-lg-3 col-md-6 mb-3" :id="'dat'+v.as_id" v-for="(v,k) in data.data" :key="k">
								<div class="wrap_slider_img">
									<img :src="uploader(v.as_img)" class="img-responsive" />
									<div class="slider_name">
										<p>{{v.as_title_en}}</p>
									</div>
									<a data-toggle="collapse" @click="editSlide(v)" href="javascript:;" class="bullet_edit"><i
											class="fas fa-pencil-alt"></i></a>
									<a href="javascript:;" @click="deleteItemId(v.as_id,'dat'+v.as_id)" class="bullet_delete"><i
											class="far fa-trash-alt"></i></a>
									<span class="label label-success" v-if="v.as_is_active == 'Y'">Active</span>
									<span class="label label-danger" v-else>Inactive</span>
								</div>
							</div>
							<div class="col-lg-3 col-md-6">
								<a class="wrap_upload" data-toggle="collapse" @click="addSlide" href="#collapseExample" role="button"
									aria-expanded="false" aria-controls="collapseExample">
									<div class="ic_wrap">
										<i class="fas fa-plus"></i>
										<p>New Slider</p>
									</div>
								</a>
							</div>
						</draggable>
						<VForm @resp="submitFormSlider" method="post">
							<div class="row collapse mt-4" id="collapseExample">
								<div class="col-12 info"></div>
								<div class="col-md-4 mb-3">
									<BoField name="as_img" mandatory>
										<Uploader :param="{thumbnail:true}" name="as_img" v-model="row.as_img" type="slideshow"
											uploadType="cropping"></Uploader>
									</BoField>
									<div class="form-group mt-3">
										<div class="form-group">
											<label class="control-label">Slideshow Motion</label>
											<select class="form-control" v-model="row.as_img_motion">
												<option v-for="(v,k) in font_motion" :key="k" :value="k">{{v}}</option>
											</select>
										</div>
									</div>
								</div>
								<div class="col-md-8 mb-3">
									<div class="row">
										<div class="col-md-12">
											<div class="panel_group">
												<div class="panel_heading">Heading</div>
												<div class="panel_body">
													<div class="row">
														<div class="col-md-9">
															<BoField name="as_title_en" v-model="row.as_title_en">
															</BoField>
														</div>
														<div class="col-md-9">
															<BoField name="as_title_id" v-model="row.as_title_id">
															</BoField>
														</div>
														<div class="col-md-3">
														</div>
														<div class="col-md-4">
															<div class="form-group">
																<label class="control-label">Font Style</label>
																<select class="form-control" v-model="row.as_title_font_type">
																	<option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
																</select>
															</div>
														</div>
														<div class="col-md-2">
															<div class="form-group">
																<label class="control-label">Font Size </label>
																<select class="form-control" v-model="row.as_title_font_size">
																	<option v-for="(v,k) in font_size" :key="k" :value="k">{{v}}</option>
																</select>
															</div>
														</div>
														<div class="col-md-2">
															<BoField :attr="{type:'color'}" name="as_title_font_color"
																v-model="row.as_title_font_color"></BoField>
														</div>
														<div class="col-md-3">
															<div class="form-group">
																<label class="control-label">Text Motion </label>
																<select class="form-control" v-model="row.as_title_motion_style">
																	<option v-for="(v,k) in font_motion" :key="k" :value="k">{{v}}</option>
																</select>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-12">
											<div class="row">
												<div class="col-md-12">
													<div class="panel_group">
														<div class="panel_heading">Sub Heading</div>
														<div class="panel_body">
															<div class="row">
																<div class="col-md-9">
																	<BoField name="as_desc_en">
																		<textarea v-model="row.as_desc_en" rows="4" class="form-control"></textarea>
																	</BoField>
																</div>

																<div class="col-md-9">
																	<BoField name="as_desc_id">
																		<textarea v-model="row.as_desc_id" rows="4" class="form-control"></textarea>
																	</BoField>
																</div>
																<div class="col-md-3">
																</div>
																<div class="col-md-4">
																	<div class="form-group">
																		<label class="control-label">Font Style</label>
																		<select class="form-control" v-model="row.as_desc_font_type">
																			<option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
																		</select>
																	</div>
																</div>
																<div class="col-md-2">
																	<div class="form-group">
																		<label class="control-label">Font Size </label>
																		<select class="form-control" v-model="row.as_desc_font_size">
																			<option v-for="(v,k) in sub_font_size" :key="k" :value="k">{{v}}</option>
																		</select>
																	</div>
																</div>
																<div class="col-md-2">
																	<BoField :attr="{type:'color'}" name="as_desc_font_color"
																		v-model="row.as_desc_font_color"></BoField>
																</div>
																<div class="col-md-3">
																	<div class="form-group">
																		<label class="control-label">Text Motion
																		</label>
																		<select class="form-control" v-model="row.as_desc_motion_style">
																			<option v-for="(v,k) in font_motion" :key="k" :value="k">{{v}}</option>
																		</select>
																	</div>
																</div>
																<div class="col-md-6">
																	<BoField name="as_is_active">
																		<div class="row">
																			<div class="col-3">
																				<radio name="as_is_active" v-model="row.as_is_active" option="Y"
																					:attr="validation('as_is_active')">
																					Active</radio>
																			</div>
																			<div class="col-3">
																				<radio name="as_is_active" v-model="row.as_is_active" option="N">
																					Inactive</radio>
																			</div>
																		</div>
																	</BoField>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-4 mt-3">
											<button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Save Changes</button>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>

			<!-- End Slider -->
			<!-- About Us -->

			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">About Us</h5>
						<div class="row">
							<div class="col-md-2 max_120">
								Show Section?
							</div>
							<div class="col-md-1">
								<div class="custom-control custom-checkbox mr-sm-2 mb-3">
									<input type="checkbox" class="custom-control-input" v-model="input.about" id="about">
									<label class="custom-control-label" for="about" checked>Yes</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- End About Us -->

			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Our Collection</h5>
						<draggable v-model="categories" class="row" tag="tbody" @end="endDragCat">
							<div style="cursor:move;" :id="'cat'+c.mpc_id" class="col-lg-2 col-md-3" v-for="(c,k) in categories">
								<div class="wrap_slider_img">
									<img :src="uploader(c.mpc_image)" class="img-responsive" />
									<div class="slider_name">
										<p>{{c.mpc_name_en}}</p>
									</div>
									<a @click="editCollection(c)" class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
									<!-- <a href="javascript:;" @click="deleteItemCat(c.mpc_id,'cat'+c.mpc_id)" class="bullet_delete"><i
											class="far fa-trash-alt"></i></a> -->
									<span class="label label-success" v-if="c.mpc_is_active == 'Y'">Active</span>
									<span class="label label-danger" v-else>Inactive</span>
								</div>
							</div>

							<div class="col-md-3" v-if="categories.length < 4">
								<a class="wrap_upload" data-toggle="collapse" @click="addCollection" href="#collapseExample2"
									role="button" aria-expanded="false" aria-controls="collapseExample2">
									<div class="ic_wrap">
										<i class="fas fa-plus"></i>
										<p>Add New Collection</p>
									</div>
								</a>
							</div>
						</draggable>
						<div class="row collapse mt-4" id="collapseExample2">
							<div class="col-md-4 mb-3">
								<BoField name="mpc_image">
									<Uploader name="mpc_image" :param="{thumbnail:true}" type="categories" uploadType="cropping"
										v-model="row2.mpc_image"></Uploader>
								</BoField>

							</div>
							<div class="col-md-8 mb-3">
								<VForm @resp="submitCollection" method="post">
									<div class="row">
										<div class="col-md-12">
											<div class="panel_group">
												<div class="panel_heading">Collection Information</div>
												<div class="panel_body">
													<div class="row">
														<div class="col-12 col-info"></div>
														<div class="col-md-9">
															<BoField name="mpc_name_en" v-model="row2.mpc_name_en">
															</BoField>
														</div>
														<div class="col-md-9">
															<BoField name="mpc_name_id" v-model="row2.mpc_name_id">
															</BoField>
														</div>
														<div class="col-sm-6">
															<BoField name="mpc_is_active">
																<div class="row">
																	<div class="col-3">
																		<radio name="mpc_is_active" v-model="row2.mpc_is_active" option="Y"
																			:attr="validation('mpc_is_active')">Active
																		</radio>
																	</div>
																	<div class="col-3">
																		<radio name="mpc_is_active" v-model="row2.mpc_is_active" option="N">
																			Inactive
																		</radio>
																	</div>
																</div>
															</BoField>
														</div>

													</div>
												</div>
											</div>
										</div>
										<div class="col-4 mt-3">
											<button type="submit" class="btn btn-rounded btn-block btn-info">Save Changes</button>
										</div>
									</div>
								</VForm>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Customer's Favourites</h5>
						<div class="row">
							<div class="col-md-2 max_120">
								Show Section?
							</div>

							<div class="col-md-1">
								<div class="custom-control custom-checkbox mr-sm-2 mb-3">
									<input type="checkbox" class="custom-control-input" v-model="input.fav" id="favorite" value="check">
									<label class="custom-control-label" for="favorite" checked>Yes</label>
								</div>
							</div>

						</div>

					</div>
				</div>
			</div>

			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Event</h5>
						<div class="row">
							<div class="col-md-2 max_120">
								Show Section?

							</div>

							<div class="col-md-1">
								<div class="custom-control custom-checkbox mr-sm-2 mb-3">
									<input type="checkbox" class="custom-control-input" v-model="input.event" id="event" value="check">
									<label class="custom-control-label" for="event" checked>Yes</label>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Blog</h5>
						<div class="row">
							<div class="col-md-2 max_120">
								Show Section?
							</div>

							<div class="col-md-1">
								<div class="custom-control custom-checkbox mr-sm-2 mb-3">
									<input type="checkbox" class="custom-control-input" v-model="input.blog" id="blog" value="check">
									<label class="custom-control-label" for="blog" checked>Yes</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Feed Instagram</h5>
						<div class="row">
							<div class="col-md-2 max_120">
								Show Section?
							</div>
							<div class="col-md-1">
								<div class="custom-control custom-checkbox mr-sm-2 mb-3">
									<input type="checkbox" class="custom-control-input" v-model="input.feed" id="feed" value="check">
									<label class="custom-control-label" for="feed" checked>Yes</label>
								</div>
							</div>
							<div class="col-md-2">
								<a :href="urlCodeInstagram" target="_blank" class="btn btn-rounded btn-loader btn-info"
									style="margin-top: -8px;">Generate</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>